import React from 'react';
import AtomicSpinner from 'atomic-spinner';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <AtomicSpinner
        electronPathColor="none"
        electronPathCount={10}
        nucleusDistanceFromCenter={15}
        electronColorPalette={['red', 'white', 'red']}
      />
    </div>
  );
};

export default Loader;
