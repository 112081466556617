import React from 'react';
import ShopHeroImg from '../../assets/shopHero.png';
import './CarsFromUsaHero.scss';

const CarsFromUsaHero = () => {
  return (
    <div className="cars_from_usa_hero">
      <div className="cars_from_usa_text_container">
        <div>
          {' '}
          {/*замінити на nav*/}
          <p>Головна</p> {/*замінити на Link*/}
          <p className="red_color"> &#xb7; Авто із США</p>{' '}
          {/*замінити на Link*/}
        </div>
        <div>
          <h1>Авто із США</h1>
        </div>
      </div>
      <img src={ShopHeroImg} alt="shop_hero_img"></img>
    </div>
  );
};

export default CarsFromUsaHero;
