import { useEffect, useState } from 'react';
import './CartItem.scss';
import CartPlus from '../../assets/cartPlus.svg';
import CartMinus from '../../assets/minusIcon.svg';

const CartItem = ({part, calculateCartPrice, handleRemoveItemFromCart}) => {

  const [partQuantity, setPartQuantity] = useState(1);

  useEffect( () => {
    part.sellingPriceSumm = part.sellingPrice;
  }, [part])
    

  const handleAddQuantity = () => {
    if(partQuantity < 9){
      setPartQuantity(prevState => prevState + 1);
      part.sellingPriceSumm = part.sellingPriceSumm + part.sellingPrice;
      calculateCartPrice();
    }
  };

  const handleQuantityReduction = () => {
    if(partQuantity > 1){
      setPartQuantity(prevState => prevState - 1);
      part.sellingPriceSumm = part.sellingPriceSumm - part.sellingPrice;
      calculateCartPrice();
    }
  };

  return (
    <div className='cart_item_container'>
      <div className='cart_item_img_container'>
        <img src={part.partImg} alt='part_img'></img>
      </div>
      <div className='cart_item_container_in'>
        <h3>{part.partName}</h3>
        <p>Номер деталі: {part.partCode}</p>
        <p>Ціна: {part.sellingPrice}$</p>
        <div className='cart_section_2_container'>
          <div className='cart_part_quantity_container'>
            <button onClick={handleQuantityReduction}>
              <img style={{width: '12px', height: '15px'}} src={CartMinus} alt='minus_svg'/>
            </button>
            <p style={{textAlign: 'center'}}>{partQuantity}</p>
            <button onClick={handleAddQuantity}>
              <img style={{width: '15px', height: '15px'}} src={CartPlus} alt='plus_svg'/>
            </button>
          </div>
          <button className='cart_remove_item_btn' onClick={() =>handleRemoveItemFromCart(part.id)}>Видалити</button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;