import CarFromUsaMain from '../../views/CarFromUsaMain/CarFromUsaMain';
import CarsFromUsaHero from '../../views/CarsFromUsaHero/CarsFromUsaHero';

const CarFromUsaPage = () => {
  return (
    <div>
      <CarsFromUsaHero />
      <CarFromUsaMain />
    </div>
  );
};

export default CarFromUsaPage;
