import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './AddCarModelModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const AddCarModelModal = ({ isModalOpen, setIsModalOpen }) => {
  const [modelName, setModelName] = useState('');
  const [carBrandId, setCarBrandId] = useState('');
  const [carBrandsList, setCarBrandsList] = useState([]);

  useEffect(() => {
    fetch('https://dev.goliusauto.com/admin/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setCarBrandsList(data))
  })

  const handleModelNameChange = event => setModelName(event.target.value);
  const handleCarBrandChange = event => setCarBrandId(event.target.value);

  const handleClose = () => setIsModalOpen(false);

  const handleCarModelSubmit = async event => {
    event.preventDefault();
    const newCarModel = {
      carBrandId: carBrandId,
      modelName: modelName,
    };
    if(carBrandId){
      try {
        const response = await fetch('https://dev.goliusauto.com/admin/avtoshrot/addNewCarModel', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newCarModel),
        });
        const data = await response.json();
        if (response.ok) {
          alert('Модель авто додана');
          setIsModalOpen(false);
        } else {
          alert('Модель авто не додана');
          console.error(data.error);
        }
      } catch (error) {
        console.error('Помилка при відправці запиту:', error);
      }
    }
    else{
      alert('Виберіть марку авто!')
    }
  };

  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Додати нову модель авто
          </Typography>
          <form className="add_car_model_form" onSubmit={handleCarModelSubmit}>
            <div>
              <div className="admin_choose_brand_container">
                <p>Оберіть марку авто</p>
                <select value={carBrandId} onChange={handleCarBrandChange}>
                  <option value={undefined}>Оберіть марку</option>
                  {carBrandsList.map(brand => {
                    return (
                      <option key={brand.id} value={brand.id}>
                        {brand.carBrandName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="admin_set_model_container">
                <p>Вкажіть нову модель авто</p>
                <input
                  type="text"
                  name="carModel"
                  value={modelName}
                  onChange={handleModelNameChange}
                  placeholder="Введіть тут назву моделі авто"
                  required
                />
              </div>
            </div>
            <button type="submit">Додати модель авто</button>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#add_car_model_modal'),
  );
};

export default AddCarModelModal;
