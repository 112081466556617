import React, { useEffect, useState } from 'react';
import Search from '../../components/Search/Search';
import './AvtoShrotMain.scss';
import CarFromShrotCard from '../../components/CarFromShrotCard/CarFromShrotCard';
import LinearIndeterminate from '../../components/LoaderLeaner/LoaderLeaner';

const AvtoShrotMain = () => {
  const [carBrands, setCarsBrands] = useState([]);

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/carBrands')
      .then(res => res.json())
      .then(data => setCarsBrands(data));
  }, []);

  return (
    <div className="avtoshrot_main">
      <Search />
      <div className="cars_avtoshrot_list">
        {carBrands.map(car => {
          return (
            <CarFromShrotCard
              carIcon={car.carIcon}
              carBrand={car.carBrandName}
              partsCount={car.partsCounter}
              key={car.id}
              carBrandId={car.id}
            />
          );
        })}
      </div>
      {carBrands.length<1 && <LinearIndeterminate/>}
    </div>
  );
};

export default AvtoShrotMain;