import React from 'react';
import ShopHeroImg from '../../assets/shopHero.png';
import './PartsHero.scss';
import Cart from '../../components/Cart/Cart';

const PartsHero = () => {
  return (
    <div className="avtoshrot_parts_hero">
      <div className="avtoshrot_parts_text_container">
        <div>
          <p>Головна</p>
          <p> &#xb7; Автошрот</p>
          <p className="red_color">&#xb7; Запчастини</p>
        </div>
        <div>
          <h1>Запчастини</h1>
        </div>
      </div>
      <img src={ShopHeroImg} className='parts_hero_img' alt="shop_hero_img"></img>
    </div>
  );
};

export default PartsHero;