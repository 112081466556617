import { useEffect, useState } from 'react';
import './Order.scss';


const Order = ({order}) => {

  const [parts, setParts] = useState([]);

  useEffect(() => {
    fetch('https://localhost:5000/admin/avtoshrot/orders/parts')
    .then(res => res.json())
    .then(data => setParts(data))
  }, [order])

  return (
    <div className="admin-order">
      <h3>Номер замовлення: {order.id}</h3>
      <p>Дата замовлення: {order.orderDate}</p>
      {order.name && <p>Ім'я: {order.name}</p>}
      <p>Номер телефону: {order.clientPhone}</p>
      {order.addres && <p>Адреса: {order.address}</p>}
      <p>Статус: {order.status ? 'Активно' : 'Закрито'}</p>
      {order.partsIds &&
      <div>
        <p>Замовлення:</p>
        <table>
          <thead>
            <tr>
              <th>Назва</th>
              <th>Номер запчастини</th>
              <th>Ціна закупочна</th>
              <th>Ціна продажу</th>
              <th>Сумма до сплати</th>
            </tr>
          </thead>
        </table>
      </div>}
    </div>
  );
};

export default Order;