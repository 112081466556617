import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './AddCarModal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const AddCarModal = ({ isModalOpen, setIsModalOpen }) => {
  const [title, setTitle] = React.useState('');
  const [year, setYear] = React.useState('');
  const [engine, setEngine] = React.useState('');
  const [driveUnit, setDriveUnit] = React.useState('');
  const [manufacturer, setManufacturer] = React.useState('');
  const [vin, setVin] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [picture, setPicture] = React.useState('');
  const [serviceable, setServiceable] = React.useState('');
  const [comingDate, setComingDate] = React.useState('');
  const [group, setGroup] = React.useState('');
  const [additionalImgs, setAdditionalImgs] = React.useState('');
  const [equipment, setEquipment] = React.useState('');
  const [description, setDescription] = React.useState('');

  const handleClose = () => setIsModalOpen(false);
  const handleTitleChange = event => setTitle(event.target.value);
  const handleYearChange = event => setYear(event.target.value);
  const handleEngineChange = event => setEngine(event.target.value);
  const handleDriveUnitChange = event => setDriveUnit(event.target.value);
  const handleManufacturerChange = event => setManufacturer(event.target.value);
  const handleVinChange = event => setVin(event.target.value);
  const handlePriceChange = event => setPrice(event.target.value);
  const handlePictureChange = event => setPicture(event.target.value);
  const handleServiceableChange = event => setServiceable(event.target.value);
  const handleComingDateChange = event => setComingDate(event.target.value);
  const handleGroupChange = event => setGroup(event.target.value);
  const handleAdditionalImgsChange = event =>
    setAdditionalImgs(event.target.value);
  const handleEquipmentChange = event => setEquipment(event.target.value);
  const handleDescriptionChange = event => setDescription(event.target.value);

  const handleCarSubmit = async event => {
    event.preventDefault();
    const carImgsArray = additionalImgs.split(' ').map(link => link.trim());
    const newCar = {
      title: title,
      year: Number(year),
      engine: engine,
      driveUnit: driveUnit,
      manufacturer: manufacturer,
      vin: vin,
      description: description,
      price: Number(price),
      picture: picture,
      serviceable: serviceable,
      equipment: equipment,
      comingDate: comingDate,
      group: group,
      additionalImgs: carImgsArray,
    };

    try {
      const response = await fetch(
        'https://dev.goliusauto.com/admin/cars-from-usa',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newCar),
        },
      );
      const data = await response.json();
      if (response.ok) {
        alert('Авто додано');
        setIsModalOpen(false);
      } else {
        alert('Авто не додано');
        console.error(data.error);
      }
    } catch (error) {
      console.error('Помилка при відправці запиту:', error);
    }
  };

  return createPortal(
    <div className="add_car_modal">
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Додати нове авто
          </Typography>
          <form className="add_car_form" onSubmit={handleCarSubmit}>
            <input
              type="text"
              name="title"
              value={title}
              onChange={handleTitleChange}
              placeholder="Введіть заголовок"
              required
            />
            <input
              type="text"
              name="year"
              value={year}
              onChange={handleYearChange}
              placeholder="Введіть рік випуску"
              required
            />
            <input
              type="text"
              name="engine"
              value={engine}
              onChange={handleEngineChange}
              placeholder="Введіть об'єм двигуна"
              required
            />
            <input
              type="text"
              name="driveUnit"
              value={driveUnit}
              onChange={handleDriveUnitChange}
              placeholder="Введіть тип приводу"
              required
            />
            <input
              type="text"
              name="manufacturer"
              value={manufacturer}
              onChange={handleManufacturerChange}
              placeholder="Введіть ім'я виробника авто"
              required
            />
            <input
              type="text"
              name="vin"
              value={vin}
              onChange={handleVinChange}
              placeholder="Введіть він код"
              required
            />
            <input
              type="text"
              name="price"
              value={price}
              onChange={handlePriceChange}
              placeholder="Введіть ціну в $"
              required
            />
            <input
              type="text"
              name="picture"
              value={picture}
              onChange={handlePictureChange}
              placeholder="Введіть посилання на головне зображення"
              required
            />
            <select value={serviceable} onChange={handleServiceableChange}>
              <option value="" disabled selected>
                Чи справне авто?
              </option>
              <option value={true}>Так</option>
              <option value={false}>Ні</option>
            </select>
            <input
              type="text"
              name="comingDate"
              value={comingDate}
              onChange={handleComingDateChange}
              placeholder="Введіть дату прибуття авто"
              required
            />
            <select value={group} onChange={handleGroupChange}>
              <option value="" disabled selected>
                Авто у наявності?
              </option>
              <option value={true}>Так</option>
              <option value={false}>Ні</option>
            </select>
            <input
              type="text"
              name="additionalImgs"
              value={additionalImgs}
              onChange={handleAdditionalImgsChange}
              placeholder="Введіть посилання на додаткові зображення через пробіл обовязково"
            />
            <input
              type="text"
              name="equipment"
              value={equipment}
              onChange={handleEquipmentChange}
              placeholder="Опишіть комплектацію"
              required
            />
            <input
              type="text"
              name="description"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Опишіть авто"
              required
            />
            <button type="submit">Додати авто</button>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#add_car_modal'),
  );
};

export default AddCarModal;
