import React, { useState } from 'react';
import './CarFromShrotCard.scss';
import ChooseCarModelModal from '../ChooseCarModelModal/ChooseCarModelModal';

const CarFromShrotCard = ({ carIcon, carBrand, partsCount, carBrandId }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <div className="car_from_shrot_card" onClick={() => setIsModalOpen(true)}>
        <div className="car_icon_container">
          <img
            src={carIcon}
            alt="car_icon_img"
            className="car_from_shrot_icon"
          ></img>
        </div>
        <div className="car_from_shrot_count">
          <p>{partsCount}</p>
        </div>
      </div>
      {isModalOpen && <ChooseCarModelModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} carIcon={carIcon} carBrand={carBrand} carBrandId={carBrandId}/>}
    </div>
  );
};

export default CarFromShrotCard;
