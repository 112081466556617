import ShopHeroImg from '../../assets/shopHero.png';
import Cart from '../../components/Cart/Cart';
import './AvtoShrotHero.scss';

const AvtoShrotHero = () => {
  return (
    <div className="avtoshrot_hero">
      <div className="avtoshrot_text_container">
        <div>
          <p>Головна</p>
          <p className="red_color"> &#xb7; Автошрот</p>
        </div>
        <div>
          <h1>Автошрот</h1>
        </div>
      </div>
      <img src={ShopHeroImg} className='avtoshrot_hero_img' alt="shop_hero_img"></img>
    </div>
  );
};

export default AvtoShrotHero;
