import CartHero from "../../views/CartHero/CartHero";
import CartMain from "../../views/CartMain/CartMain";


const CartPage = () => {
  return (
    <div>
      <CartHero/>
      <CartMain/>
    </div>
  );
};

export default CartPage;