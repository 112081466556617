import React, { useState } from 'react';
import './AdminAvtoshrotMain.scss';
import AddCarModelModal from '../../components/AddCarModelModal/AddCarModelModal';
import DeleteCarModelModal from '../../components/DeleteCarModelModal/DeleteCarModelModal';
import AddPartModal from '../../components/AddPartModal/AddPartModal';
import DeletePartModal from '../../components/DeletePartModal/DeletePartModal';
import EditPartModal from '../../components/EditPartModal/EditPartModal';
import AnalogsConnectModal from '../../components/AnalogsConnectModal/AnalogsConnectModal';

const AdminAvtoshrotMain = () => {
  const [isAddCarModelModalOpen, setIsAddCarModelModalOpen] = useState(false);
  const [isDeleteCarModelModalOpen, setIsDeleteCarModelModalOpen] = useState(false);
  const [isAddPartModalOpen, setIsAddPartModalOpen] = useState(false);
  const [isDeletePartModalOpen, setIsDeletePartModalOpen] = useState(false);
  const [isEditPartModalOpen, setIsEditPartModalOpen] = useState(false);
  const [isAnalogsModalOpen, setIsAnalogsModalOpen] = useState(false);

  return (
    <div>
      <h1 className="admin_func_text">Функції</h1>
      <div className="admin_func_buttons_group">
        <button onClick={() => setIsAddCarModelModalOpen(true)}>
          Додати модель авто
        </button>
        {isAddCarModelModalOpen && (
          <AddCarModelModal
            isModalOpen={isAddCarModelModalOpen}
            setIsModalOpen={setIsAddCarModelModalOpen}
          />
        )}
        <button onClick={() => setIsDeleteCarModelModalOpen(true)}>
          Видалити модель авто
        </button>
        {isDeleteCarModelModalOpen && (
          <DeleteCarModelModal
            isModalOpen={isDeleteCarModelModalOpen}
            setIsModalOpen={setIsDeleteCarModelModalOpen}
          />
        )}
        <button onClick={() => setIsAddPartModalOpen(true)}>Додати запчастину</button>
        {isAddPartModalOpen && (
          <AddPartModal
            isModalOpen={isAddPartModalOpen}
            setIsModalOpen={setIsAddPartModalOpen}
          />)}
        <button onClick={() => setIsEditPartModalOpen(true)}>Редагуати запчастину</button>
        {isEditPartModalOpen && (
          <EditPartModal
            isModalOpen={isEditPartModalOpen}
            setIsModalOpen={setIsEditPartModalOpen}
          />
        )}
        <button onClick={() => setIsDeletePartModalOpen(true)}>Видалити запчастину</button>
        {isDeletePartModalOpen && (
          <DeletePartModal
            isModalOpen={isDeletePartModalOpen}
            setIsModalOpen={setIsDeletePartModalOpen}
          />
        )}
        <button onClick={() => setIsAnalogsModalOpen(true)}>Підвязка аналогів</button>
        {isAnalogsModalOpen && (
          <AnalogsConnectModal
            isModalOpen={isAnalogsModalOpen}
            setIsModalOpen={setIsAnalogsModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default AdminAvtoshrotMain;
