import React, { useState } from 'react';
import './Search.scss';

const Search = () => {
  const [searchWord, setSearchWord] = useState('');

  const handleChangeWord = event => setSearchWord(event.target.value);

  const handleKeyPress = event => {
    if (event.key === 'Enter' && searchWord) {
      window.location.href = `/avtoshrot/search/${searchWord}`;
    }
  };

  const handleSubmit = () => {
    if(searchWord){
      window.location.href = `/avtoshrot/search/${searchWord}`;
    }
    else{
      alert('Напишіть номер запчастини у пошук');
    }
  }

  return (
    <div className="search_container">
      <input
        className="search_input"
        placeholder="Пошук"
        value={searchWord}
        onChange={handleChangeWord}
        onKeyDown={handleKeyPress}
      />
      <button onClick={handleSubmit} className="search_button">Знайти</button>
    </div>
  );
};

export default Search;
