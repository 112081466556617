import React from 'react';
import './PartFromShrotCard.scss';

const PartFromShrotCard = ({part}) => {

  const handlePartPick = () => {
    window.location.href = `/avtoshrot/parts/${part.id}`;
  }

  return (
    <div className='part_card_from_shrot_container'>
      <p className='part_card_from_shrot_partName'>{part.partName}</p>
      <img src={part.partImg} alt='part_img'></img>
      <div className='part_card_from_shrot_descr_button_container'>
        <div className='part_card_from_shrot_descr_container'>
          <p>Код запчастини: {part.partCode}</p>
          <p>Стан: {part.state}</p>
          <p>Виробник: {part.manufacturer}</p>
          <p>Наявність: {part.quantity}шт</p>
          <p className='part_card_from_shrot_price'>Ціна: {part.sellingPrice}$</p>
        </div>
        <div className='part_card_from_shrot_button_container'>
          <button onClick={handlePartPick}>Перейти</button>
        </div>
      </div>
    </div>
  );
};

export default PartFromShrotCard;