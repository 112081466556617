import React from 'react';
import './PartFromShrotCardPC.scss';

const PartFromShrotCardPC = ({part}) => {

  const handlePartPick = () => {
    window.location.href = `/avtoshrot/parts/${part.id}`;
  }

  return (
    <tr className='part_card_from_shrot_container_PC'>
      <td className='part_card_from_shrot_img_container_PC'>
        <img src={part.partImg} alt='part_img'></img>
      </td>
      <td>{part.partName}</td>
      <td>{part.manufacturer}</td>
      <td>{part.partCode}</td>
      <td>{part.state}</td>
      <td>{part.quantity}шт.</td>
      <td>{part.sellingPrice}$</td>
      <td>
        <button onClick={handlePartPick} className='part_card_from_shrot_button_PC'>Перейти</button> 
      </td>
    </tr>
  );
};

export default PartFromShrotCardPC;