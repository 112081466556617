import React from 'react';
import AvtoShrotMain from '../../views/AvtoShrotMain/AvtoShrotMain';
import AvtoShrotHero from '../../views/AvtoShrotHero/AvtoShrotHero';

const AvtoShrotPage = () => {
  return (
    <div>
      <AvtoShrotHero />
      <AvtoShrotMain />
    </div>
  );
};

export default AvtoShrotPage;
