import { useEffect, useState } from "react";
import PartsHero from "../PartsHero/PartsHero";
import './ShrotPart.scss';

const ShrotPart = () => {

  const [part, setPart] = useState();
  const [isAddedToCart, setIsAddedToCart] = useState(false);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const partId = urlParts[urlParts.length - 1];
    fetch(`https://dev.goliusauto.com/avtoshrot/parts/${partId}`)
    .then(res => res.json())
    .then(data => setPart(data))
  }, [])

  useEffect(() => {
    if(part){
      const existingData = JSON.parse(localStorage.getItem('partsInCart')) || [];
      if(existingData.includes(part.id)){
        setIsAddedToCart(true);
      }
    }
  }, [part])

  const handleAddToCart = () => {
    const existingData = JSON.parse(localStorage.getItem('partsInCart')) || [];
    if(!existingData.includes(part.id)) {
      const newData = [...existingData, part.id];
      localStorage.setItem('partsInCart', JSON.stringify(newData));
      setIsAddedToCart(true);
    }
    window.location.reload();
  }

  return (
    <div>
      <PartsHero/>
      {part && <div className="part_container">
        <div className="part_img_container">
          <img src={part.partImg} alt="part_img"></img>
        </div>
        <div className="part_descr_container">
          <h2>{part.partName}</h2>
          <p>Код запчастини: {part.partCode}</p>
          <p>Виробник: {part.manufacturer}</p>
          <p>Стан: {part.state}</p>
          <p>Наявність {part.quantity}шт.</p>
          {part.description && <p>Примітка: {part.description}</p>}
          <p style={{fontSize: '1.5rem', color: 'green'}}>Ціна: {part.sellingPrice}$</p>
          {!isAddedToCart && <div className="add_to_cart_btn_container">
            <button className="add_to_cart_btn" onClick={handleAddToCart}>Додати до кошику</button>
          </div>}
          {isAddedToCart && <h2 style={{color: 'green'}}>Додано до кошику ✔</h2>}
          <h2 className = "consultation_h2">Консультація: +380937518846</h2>
        </div>
      </div>}
    </div>
  );
};

export default ShrotPart;