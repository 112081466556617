import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './OrderModal.scss';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const OrderModal = ({ isModalOpen, setIsModalOpen, partsList }) => {

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAdress] = useState('');
  const [isOrderSended, setIsOrderSended] = useState(false);

  const handleNameChange = event => setName(event.target.value);
  const handlePhoneChange = event => setPhone(event.target.value);
  const handleAdressChange = event => setAdress(event.target.value);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const newOrder = {
      type: 'parts',
      name: name,
      clientPhone: phone,
      address: address,
      partsIds: JSON.parse(partsList)
    }
    const res = await fetch('https://dev.goliusauto.com/avtoshrot/order', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newOrder)
    })
    if(res){
      setIsOrderSended(true);
      localStorage.clear();
    }
  }

  const handleClose = () => {
    setIsModalOpen(false);
  }
  
  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          {!isOrderSended && <Typography id="modal-modal-title" variant="h6" component="h2">
            Заповніть контактні дані
          </Typography>}
          {!isOrderSended && <form className='add_part_form' onSubmit={handleFormSubmit}>
            <div className='input_order_group'>
              <label>Ім'я</label>
              <input 
                type="text" 
                name='name'
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
            <div className='input_order_group'>
              <label>Номер телефону</label>
              <input 
                type="number" 
                name='phone'
                value={phone}
                onChange={handlePhoneChange}
                required
              />
            </div>
            <div className='input_order_group'>
              <label>Вкажіть адресу доставки</label>
              <input 
                type="text" 
                name='address'
                value={address}
                onChange={handleAdressChange}
                required
              />
            </div>
            <button type='submit' className='order_submit_btn'>Оформити замовлення</button>
          </form>}
          {isOrderSended && <h2>Замовлення відправлено в обробку</h2>}
        </Box>
      </Modal>
    </div>,
    document.querySelector('#order_modal'),
  );
};

export default OrderModal;
