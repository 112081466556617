import React from 'react';
import './Consultation.scss';

const Consultation = () => {
  return (
    <div className='consultation_container'>
      <h2>Консультація</h2>
      <div>
        <p>Вкажіть номер телефону</p>
        <input/>
      </div>
      <button>Замовити консультацію</button>
    </div>
  );
};

export default Consultation;