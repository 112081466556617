import React from 'react';
import ShopHeroImg from '../../assets/shopHero.png';

const CartHero = () => {
  return (
    <div className="avtoshrot_hero">
      <div className="avtoshrot_text_container">
        <div>
          <p>Головна</p>
          <p> &#xb7; Автошрот</p>
          <p className="red_color">&#xb7; Кошик</p>
        </div>
        <div>
          <h1>Кошик</h1>
        </div>
      </div>
        <img src={ShopHeroImg} className='avtoshrot_hero_img' alt="shop_hero_img"></img>
    </div>
  );
};

export default CartHero;