import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './AddPartModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const AddPartModal = ({ isModalOpen, setIsModalOpen }) => {

  const [brandsList, setBrandsList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [selectedModel, setSelectedModel] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [partName, setPartName] = useState('');
  const [partCode, setPartCode] = useState('');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [quantity, setQuantity] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [partPicture, setPartPicture] = useState('');
  const [description, setDescription] = useState('');
  const [additionalPhotos, setAdditionalPhotos] = useState('');

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setBrandsList(data))
  }, [])

  useEffect(() => {
    if(selectedBrand){
      fetch(`https://dev.goliusauto.com/avtoshrot/${selectedBrand}/carModels`)
      .then(res => res.json())
      .then(data => setModelsList(data))
    }
    else{
      setModelsList([]);
    }
  }, [selectedBrand])

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/categories')
    .then(res => res.json())
    .then(data => setCategoriesList(data))
  }, [])

  useEffect(() => {
    if(selectedCategory){
      fetch(`https://dev.goliusauto.com/avtoshrot/${selectedCategory}/subCategories`)
      .then(res => res.json())
      .then(data => setSubCategoriesList(data))
    }
    else{
      setSubCategoriesList([]);
    }
  }, [selectedCategory])

  const handleBrandChange = event => {
    setSelectedBrand(event.target.value);
    setSelectedModel(undefined);
  }
  const handleModelChange = event => setSelectedModel(event.target.value);
  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(undefined);
  }
  const handleSubCategoryChange = event => setSelectedSubCategory(event.target.value);
  const handlePartNameChange = event => setPartName(event.target.value);
  const handlePartCodeChange = event => setPartCode(event.target.value);
  const handlePurchasePriceChange = event => setPurchasePrice(event.target.value);
  const handleSellingPriceChange = event => setSellingPrice(event.target.value);
  const handleSelectedStateChange = event => setSelectedState(event.target.value);
  const handleQuantityChange = event => setQuantity(event.target.value);
  const handleManufacturerChange = event => setManufacturer(event.target.value);
  const handlePartPictureChange = event => setPartPicture(event.target.value);
  const handleDescriptionChange = event => setDescription(event.target.value);
  const handleAdditionalPhotosChange = event => setAdditionalPhotos(event.target.value);

  const handleClose = () => setIsModalOpen(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if(selectedBrand && selectedModel && selectedCategory && selectedSubCategory){
      const newPart = {
        brandId: selectedBrand,
        modelId: selectedModel,
        categoryId: selectedCategory,
        subCategoryId: selectedSubCategory,
        partName: partName,
        partCode: partCode,
        purchasePrice: purchasePrice,
        sellingPrice: sellingPrice,
        state: selectedState,
        quantity: quantity,
        manufacturer: manufacturer,
        partImg: partPicture,
        description: description,
        additionalPhotos: additionalPhotos.split(' ').map(link => link.trim())
      }
      try {
        const response = await fetch('https://dev.goliusauto.com/admin/avtoshrot/addNewCarPart', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newPart),
        });
        const data = await response.json();
        if (response.ok) {
          alert('Автозапчастина додана');
          setIsModalOpen(false);
        } else {
          alert('Автозапчастина не додана');
          console.error(data.error);
        }
      } catch (error) {
        console.error('Помилка при відправці запиту:', error);
      }
    }
    else{
      alert('Виберіть марку, модель, категорію, підкатегорію')
    }
  }
  
  
  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Додати автозапчастину
          </Typography>
          <form className='add_part_form' onSubmit={handleFormSubmit}>
            <select value={selectedBrand} onChange={handleBrandChange} required>
              {!selectedBrand && <option value={undefined}>Оберіть марку авто</option>}
              {brandsList.map((brand) => {
                return(
                  <option key={brand.id} value={brand.id}>{brand.carBrandName}</option>
                )
              })}
            </select>
            <select value={selectedModel} onChange={handleModelChange} required>
              {!selectedModel && <option value={undefined}>Оберіть модель авто</option>}
              {modelsList.map((model) => {
                return(
                  <option key={model.id} value={model.id}>{model.modelName}</option>
                )
              })}
            </select>
            <select value={selectedCategory} onChange={handleCategoryChange} required>
              {!selectedCategory && <option value={undefined}>Оберіть категорію</option>}
              {categoriesList.map((category) => {
                return(
                  <option key={category.id} value={category.id}>{category.categoryName}</option>
                )
              })}
            </select>
            <select value={selectedSubCategory} onChange={handleSubCategoryChange} required>
              {!selectedSubCategory && <option value={undefined}>Оберіть підкатегорію</option>}
              {subCategoriesList.map((subCategory) => {
                return(
                  <option key={subCategory.id} value={subCategory.id}>{subCategory.subCategoryName}</option>
                )
              })}
            </select>
            <input 
              placeholder='Вкажіть назву запчастини'
              type='text'
              name='partName'
              value={partName}
              onChange={handlePartNameChange}
              required
              />
            <input 
              placeholder='Вкажіть код запчастини'
              type='text'
              name='partCode'
              value={partCode}
              onChange={handlePartCodeChange}
              required
            />
            <input
             placeholder='Закупівельна ціна'
             type='number'
             name='purchasePrice'
             value={purchasePrice}
             onChange={handlePurchasePriceChange}
             required
             />
            <input 
              placeholder='Ціна продажу'
              type='number'
              name='sellingPrice'
              value={sellingPrice}
              onChange={handleSellingPriceChange}
              required
              />
            <select value={selectedState} onChange={handleSelectedStateChange} required>
              {!selectedState && <option value={undefined}>Вкажіть стан</option>}
              <option value='Новий'>Новий</option>
              <option value='Б/У'>Б/У</option>
            </select>
            <input
             placeholder='Кількість в наявності'
             type='number'
             name='quantity'
             value={quantity}
             onChange={handleQuantityChange}
             required
            />
            <input 
              placeholder='Вкажіть виробника'
              type='text'
              name='manufacturer'
              value={manufacturer}
              onChange={handleManufacturerChange}
              required
            />
            <input
              placeholder='Вкажіть посилання на зображення'
              type='text'
              name='picture_link'
              value={partPicture}
              onChange={handlePartPictureChange}
            />
            <input 
              placeholder='Опис'
              type='text'
              name='description'
              value={description}
              onChange={handleDescriptionChange}
            />
            <textarea
             style={{resize: 'none'}} 
             rows="4" 
             cols="50"
             name='additionalPhotos'
             placeholder='Вкажіть посилання на додаткові зображення через пробіл'
             value={additionalPhotos}
             onChange={handleAdditionalPhotosChange}
             ></textarea>
            <hr style={{width: '100%'}}/>
            <button type='submit'>Додати запчастину</button>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#add_part_modal'),
  );
};

export default AddPartModal;
