import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './AnalogsConnectModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import PartFromShrotAnalogsCard from '../PartFromShrotAnalogsCard/PartFromShrotAnalogsCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: 'fit-content',
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const AnalogsConnectModal = ({ isModalOpen, setIsModalOpen }) => {
  const [carBrandId, setCarBrandId] = useState('');
  const [carModelId, setCarModelId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [partId, setPartId] = useState('');
  const [carBrandsList, setCarBrandsList] = useState([]);
  const [carModelsList, setCarModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [partsList, setPartsList] = useState([]);

  const [analogsBrandId, setAnalogsBrandId] = useState('');
  const [analogsModelId, setAnalogsModelId] = useState('');
  const [analogsCategoryId, setAnalogsCategoryId] = useState('');
  const [analogsSubCategoryId, setAnalogsSubCategoryId] = useState('');
  const [analogsBrandsList, setAnalogsBrandsList] = useState([]);
  const [analogsModelsList, setAnalogsModelsList] = useState([]);
  const [analogsCategoriesList, setAnalogsCategoriesList] = useState([]);
  const [analogsSubCategoriesList, setAnalogsSubCategoriesList] = useState([]);
  const [analogsPartsList, setAnalogsPartsList] = useState([]);
  const [selectedAnalogsIds, setSelectedAnalogsIds] = useState([]);
  

  useEffect(() => {
    fetch('https://dev.goliusauto.com/admin/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setCarBrandsList(data))
  }, [])

  useEffect(() => {
    fetch('https://dev.goliusauto.com/admin/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setAnalogsBrandsList(data))
  }, [])

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/categories')
    .then(res => res.json())
    .then(data => setCategoriesList(data))
  }, [])

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/categories')
    .then(res => res.json())
    .then(data => setAnalogsCategoriesList(data))
  }, [])

  useEffect(() => {
    if(categoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/${categoryId}/subCategories`)
      .then(res => res.json())
      .then(data => setSubCategoriesList(data))
    }
  }, [categoryId])

  useEffect(() => {
    if(analogsCategoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/${analogsCategoryId}/subCategories`)
      .then(res => res.json())
      .then(data => setAnalogsSubCategoriesList(data))
    }
  }, [analogsCategoryId])

  useEffect(() => {
    if(carBrandId)
    fetch(`https://dev.goliusauto.com/admin/avtoshrot/carModels/${carBrandId}`)
    .then(res => res.json())
    .then(data => setCarModelsList(data.slice().sort((a, b) => {
      const modelNameA = a.modelName.toUpperCase();
      const modelNameB = b.modelName.toUpperCase();

      if (modelNameA < modelNameB) {
        return -1;
      } else if (modelNameA > modelNameB) {
        return 1;
      } else {
        return 0;
      }
    })))
  }, [carBrandId])

  useEffect(() => {
    if(analogsBrandId)
    fetch(`https://dev.goliusauto.com/admin/avtoshrot/carModels/${analogsBrandId}`)
    .then(res => res.json())
    .then(data => setAnalogsModelsList(data.slice().sort((a, b) => {
      const modelNameA = a.modelName.toUpperCase();
      const modelNameB = b.modelName.toUpperCase();

      if (modelNameA < modelNameB) {
        return -1;
      } else if (modelNameA > modelNameB) {
        return 1;
      } else {
        return 0;
      }
    })))
  }, [analogsBrandId])

  useEffect(() => {
    if(carBrandId && carModelId && categoryId && subCategoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/parts/${carBrandId}/${carModelId}/${categoryId}/${subCategoryId}`)
      .then(res => res.json())
      .then(data => setPartsList(data))
    }
  }, [carBrandId, carModelId, categoryId, subCategoryId])

  useEffect(() => {
    if(analogsBrandId && analogsModelId && analogsCategoryId && analogsSubCategoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/parts/${analogsBrandId}/${analogsModelId}/${analogsCategoryId}/${analogsSubCategoryId}`)
      .then(res => res.json())
      .then(data => setAnalogsPartsList(data))
    }
  }, [analogsBrandId, analogsModelId, analogsCategoryId, analogsSubCategoryId])

  const handleCarBrandChange = event => setCarBrandId(event.target.value);
  const handleCarModelChange = event => setCarModelId(event.target.value);
  const handleCategoryChange = event => setCategoryId(event.target.value);
  const handleSubCategoryChange = event => setSubCategoryId(event.target.value);
  const handlePartChange = event => setPartId(event.target.value);

  const handleAnalogsBrandChange = event => setAnalogsBrandId(event.target.value);
  const handleAnalogsModelChange = event => setAnalogsModelId(event.target.value);
  const handleAnalogsCategoryChange = event => setAnalogsCategoryId(event.target.value);
  const handleAnalogsSubCategoryChange = event => setAnalogsSubCategoryId(event.target.value);

  const handleClose = () => setIsModalOpen(false);

  const handleAnalogsPartsSelect = (id) => {
    if(selectedAnalogsIds.includes(id)){
      const newArr = selectedAnalogsIds.filter(word => word !== id)
      setSelectedAnalogsIds(newArr);
    }
    else{
      setSelectedAnalogsIds(prevIds => [...prevIds, id])
    }
  }

  const handleCarPartSubmit = async event => {
    event.preventDefault();
    if(selectedAnalogsIds.length > 0 && partId){
      const newObj = {
        partId: partId,
        analogsArray: selectedAnalogsIds
      }
      const result = await fetch('https://dev.goliusauto.com/admin/avtoshrot/parts/connectAnalogs', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newObj),
      })
      if(result){
        alert('Аналоги підвязано')
        setIsModalOpen(false);
      }
      else{
        alert('Аналоги не підвязано')
      }
    }
    else{
      alert('Оберіть запчастину до якої підвязати і запчастини які підвязати');
    }
    
    
  };

  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Підвязка Аналогів
          </Typography>
          <form
            className="analogs_connect_car_parts_form"
            onSubmit={handleCarPartSubmit}
          >
            <div>
              <h2>Оберіть запчастину до якої підвязати</h2>
              <div className="admin_parts_choose_brand_container">
                <p>Оберіть марку авто</p>
                <select value={carBrandId} onChange={handleCarBrandChange}>
                  <option value={undefined}>Оберіть марку</option>
                  {carBrandsList.map(carBrand => {
                    return (
                      <option key={carBrand.id} value={carBrand.id}>
                        {carBrand.carBrandName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_model_container">
                <p>Оберіть модель авто</p>
                <select value={carModelId} onChange={handleCarModelChange}>
                  <option value={null}>Оберіть модель</option>
                  {carModelsList.map(model => {
                    return <option key={model.id} value={model.id}>{model.modelName}</option>;
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_category_container">
                <p>Оберіть категорію</p>
                <select value={categoryId} onChange={handleCategoryChange}>
                  <option value={null}>Оберіть категорію</option>
                  {categoriesList.map(category => {
                    return <option key={category.id} value={category.id}>{category.categoryName}</option>;
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_subCategory_container">
                <p>Оберіть підкатегорію</p>
                <select value={subCategoryId} onChange={handleSubCategoryChange}>
                  <option value={null}>Оберіть підкатегорію</option>
                  {subCategoriesList.map(subCategory => {
                    return <option key={subCategory.id} value={subCategory.id}>{subCategory.subCategoryName}</option>;
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_subCategory_container">
                <p>Оберіть запчастину</p>
                <select value={partId} onChange={handlePartChange}>
                  <option value={null}>Оберіть запчастину</option>
                  {partsList.map(part => {
                    return <option key={part.id} value={part.id}>{part.partName}    Код: {part.partCode}</option>;
                  })}
                </select>
              </div>
              <div>
                <h2>Оберіть запчастини які підвязати</h2>
                <div>
                  <div className="admin_parts_choose_brand_container">
                    <p>Оберіть марку авто</p>
                    <select value={analogsBrandId} onChange={handleAnalogsBrandChange}>
                      <option value={undefined}>Оберіть марку</option>
                      {analogsBrandsList.map(carBrand => {
                        return (
                          <option key={carBrand.id} value={carBrand.id}>
                            {carBrand.carBrandName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="admin_parts_choose_model_container">
                    <p>Оберіть модель авто</p>
                    <select value={analogsModelId} onChange={handleAnalogsModelChange}>
                      <option value={null}>Оберіть модель</option>
                      {analogsModelsList.map(model => {
                        return <option key={model.id} value={model.id}>{model.modelName}</option>;
                      })}
                    </select>
                  </div>
                  <div className="admin_parts_choose_category_container">
                    <p>Оберіть категорію</p>
                    <select value={analogsCategoryId} onChange={handleAnalogsCategoryChange}>
                      <option value={null}>Оберіть категорію</option>
                      {analogsCategoriesList.map(category => {
                        return <option key={category.id} value={category.id}>{category.categoryName}</option>;
                      })}
                    </select>
                  </div>
                  <div className="admin_parts_choose_subCategory_container">
                    <p>Оберіть підкатегорію</p>
                    <select value={analogsSubCategoryId} onChange={handleAnalogsSubCategoryChange}>
                      <option value={null}>Оберіть підкатегорію</option>
                      {analogsSubCategoriesList.map(subCategory => {
                        return <option key={subCategory.id} value={subCategory.id}>{subCategory.subCategoryName}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <button type="submit">Підвязати аналоги</button>
            </div>
            <div className='parts_list_analogs_container'>
              <table className='parts_list_analogs'>
                <thead>
                  <tr>
                    <th></th>
                    <th>Назва деталі</th>
                    <th>Виробник</th>
                    <th>Номер деталі</th>
                    <th>Ціна</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {analogsPartsList.map((part) => {
                    return(
                        <PartFromShrotAnalogsCard
                          part={part}
                          key={part.id}
                          handleAnalogsPartsSelect={handleAnalogsPartsSelect}
                          selectedAnalogsIds={selectedAnalogsIds}
                        />
                    )
                  })}
                </tbody>
            </table>
            </div>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#analogs_modal'),
  );
};

export default AnalogsConnectModal;
