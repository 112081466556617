import './WallpaperCarousel.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const WallpaperCarousel = ({ images }) => {
  return (
    <Carousel showThumbs={false}>
      {images.map((imageUrl, index) => (
        <div key={index}>
          <img src={imageUrl} alt={`Wallpaper ${index}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default WallpaperCarousel;
