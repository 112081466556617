import { useEffect, useState } from 'react';
import WallpaperCarousel from '../../components/WallpaperCarousel/WallpaperCarousel';
import './CarFromUsaMain.scss';

const CarFromUsaMain = () => {
  const [car, setCar] = useState();

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const carId = urlParts[urlParts.length - 1];
    fetch(`https://dev.goliusauto.com/cars-from-usa/${carId}`)
      .then(res => res.json())
      .then(data => setCar(data));
  }, []);

  return (
    <div className="car_from_usa_main">
      {car && (
        <>
          <div className="imgs_container">
            <WallpaperCarousel images={car.additionalImgs} />
          </div>
          <div className="details_and_descr_container">
            <div className="details_container">
              <h1>{car.title}</h1>
              <p>Об'єм двигуна: {car.engine}</p>
              <p>Тип приводу: {car.driveUnit}</p>
              <p>Рік випуску: {car.year}</p>
              <p>VIN: {car.vin}</p>
              <p className="car_price">Ціна: {car.price}$</p>
            </div>
            <div className="description_container">
              <div>
                <h2>Комплектація</h2>
                <p>{car.equipment}</p>
              </div>
              <div>
                <h2>Опис</h2>
                <p>{car.description}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CarFromUsaMain;
