import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './DeletePartModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const DeletePartModal = ({ isModalOpen, setIsModalOpen }) => {
  const [carBrandId, setCarBrandId] = useState('');
  const [carModelId, setCarModelId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [partId, setPartId] = useState('');
  const [carBrandsList, setCarBrandsList] = useState([]);
  const [carModelsList, setCarModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [partsList, setPartsList] = useState([]);
  

  useEffect(() => {
    fetch('https://dev.goliusauto.com/admin/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setCarBrandsList(data))
  }, [])

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/categories')
    .then(res => res.json())
    .then(data => setCategoriesList(data))
  }, [])

  useEffect(() => {
    if(categoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/${categoryId}/subCategories`)
      .then(res => res.json())
      .then(data => setSubCategoriesList(data))
    }
  }, [categoryId])

  useEffect(() => {
    if(carBrandId)
    fetch(`https://dev.goliusauto.com/admin/avtoshrot/carModels/${carBrandId}`)
    .then(res => res.json())
    .then(data => setCarModelsList(data.slice().sort((a, b) => {
      const modelNameA = a.modelName.toUpperCase();
      const modelNameB = b.modelName.toUpperCase();

      if (modelNameA < modelNameB) {
        return -1;
      } else if (modelNameA > modelNameB) {
        return 1;
      } else {
        return 0;
      }
    })))
  }, [carBrandId])

  useEffect(() => {
    if(carBrandId && carModelId && categoryId && subCategoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/parts/${carBrandId}/${carModelId}/${categoryId}/${subCategoryId}`)
      .then(res => res.json())
      .then(data => setPartsList(data))
    }
  }, [carBrandId, carModelId, categoryId, subCategoryId])

  const handleCarBrandChange = event => setCarBrandId(event.target.value);
  const handleCarModelChange = event => setCarModelId(event.target.value);
  const handleCategoryChange = event => setCategoryId(event.target.value);
  const handleSubCategoryChange = event => setSubCategoryId(event.target.value);
  const handlePartChange = event => setPartId(event.target.value);

  const handleClose = () => setIsModalOpen(false);

  const handleCarPartSubmit = async event => {
    event.preventDefault();

    if(partId){
      const response = await fetch(`https://dev.goliusauto.com/admin/avtoshrot/deletePart/${partId}`, {
        method: 'DELETE'
      })
      if(response.ok){
        alert('Запчастину видалено');
        setIsModalOpen(false);
      }
      else{
        alert('Запчастину не видалено');
      }
    }
    else{
      alert('Оберіть марку модель категорію та підкатегорію запчастини');
    }
  };

  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Видалити Автозапчастину
          </Typography>
          <form
            className="delete_car_part_form"
            onSubmit={handleCarPartSubmit}
          >
            <div>
              <div className="admin_parts_choose_brand_container">
                <p>Оберіть марку авто</p>
                <select value={carBrandId} onChange={handleCarBrandChange}>
                  <option value={undefined}>Оберіть марку</option>
                  {carBrandsList.map(carBrand => {
                    return (
                      <option key={carBrand.id} value={carBrand.id}>
                        {carBrand.carBrandName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_model_container">
                <p>Оберіть модель авто</p>
                <select value={carModelId} onChange={handleCarModelChange}>
                  <option value={null}>Оберіть модель</option>
                  {carModelsList.map(model => {
                    return <option key={model.id} value={model.id}>{model.modelName}</option>;
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_category_container">
                <p>Оберіть категорію</p>
                <select value={categoryId} onChange={handleCategoryChange}>
                  <option value={null}>Оберіть категорію</option>
                  {categoriesList.map(category => {
                    return <option key={category.id} value={category.id}>{category.categoryName}</option>;
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_subCategory_container">
                <p>Оберіть підкатегорію</p>
                <select value={subCategoryId} onChange={handleSubCategoryChange}>
                  <option value={null}>Оберіть підкатегорію</option>
                  {subCategoriesList.map(subCategory => {
                    return <option key={subCategory.id} value={subCategory.id}>{subCategory.subCategoryName}</option>;
                  })}
                </select>
              </div>
              <div className="admin_parts_choose_subCategory_container">
                <p>Оберіть запчастину</p>
                <select value={partId} onChange={handlePartChange}>
                  <option value={null}>Оберіть запчастину</option>
                  {partsList.map(part => {
                    return <option key={part.id} value={part.id}>{part.partName}    Код: {part.partCode}</option>;
                  })}
                </select>
              </div>
            </div>
            <button type="submit">Видалити запчастину</button>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#delete_part_modal'),
  );
};

export default DeletePartModal;
