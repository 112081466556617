import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './OrdersMain.scss';
import Order from '../../components/Order/Order';

const OrdersMain = () => {

  const [orderType, setOrderType] = useState(useLocation().pathname.split('/').pop());
  const [orders, setOrders] = useState([]);

  const currentLink = useLocation().pathname;

  useEffect(() => {
    setOrderType(currentLink.split('/').pop())
  }, [currentLink]);

  useEffect(() => {
    fetch(`https://dev.goliusauto.com/admin/avtoshrot/orders/${orderType}`)
    .then(res => res.json())
    .then(data => setOrders(data));
  }, [orderType]);

  return (
    <div className='order-main'>
      <h2>{orderType==='consultation' ? 'Консультації' : 'Запчастини'}</h2>
      <div className='admin_orders_list'>
        {orders.map((order) => {
          return <Order key={order.id} order={order}/>
        })}
      </div>
    </div>
  );
};

export default OrdersMain;