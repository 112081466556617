import { useEffect, useState } from 'react';
import './CartMain.scss';
import CartItem from '../../components/CartItem/CartItem';
import OrderModal from '../../components/OrderModal/OrderModal';

const CartMain = () => {

  const [parts, setParts] = useState([]);
  const [partsFromStorage, setPartsFromStorage] = useState();
  const [cartPrice, setCartPrice] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    const storageData = localStorage.getItem('partsInCart');
    setPartsFromStorage(storageData);
  }, []);


  useEffect(() => {
    if(partsFromStorage){
      fetch('https://dev.goliusauto.com/avtoshrot/cart', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: partsFromStorage,
      })
      .then(res => res.json())
      .then(data => setParts(data))
    }
  }, [partsFromStorage]);

  
  const calculateCartPrice = () => {
    let price = 0;
    for(let part of parts){
      price = price + part.sellingPriceSumm;
    }
    setCartPrice(price);
  }
  
  useEffect(calculateCartPrice, [parts])

  const handleRemoveItemFromCart = async (id) => {
    const newArrayToClient = parts.filter(item => item.id !== id);
    setParts(newArrayToClient);
    const existingData = JSON.parse(localStorage.getItem('partsInCart')) || [];
    const updatedArray = existingData.filter(item => item !== id);
    const resultString = updatedArray.map(item => `"${item}"`).join(', ');
    localStorage.setItem('partsInCart', `[${resultString}]`)
    window.location.reload();
  }

  const handleOpenForm = () => {
    setIsFormOpen(true);
  }

  return (
    <div className='cart_main_container'>
      <h1 className='cart_h1_name'>Кошик</h1>
      {parts.length>0 && <div className='cart_items_list'>
        {parts.map((part) => {
          return(
            <CartItem
              part={part}
              key={part.id}
              calculateCartPrice={calculateCartPrice}
              handleRemoveItemFromCart={handleRemoveItemFromCart}
            />
          )
        })}
      </div>}
      {parts.length>0 && <p className='cart_price_info'>Загальна вартість: {cartPrice}$</p>}
      {parts.length>0 && <button onClick={handleOpenForm} className='order_submit_btn'>Оформити замовлення</button>}
      {parts.length === 0 && <h2 style={{textAlign: 'center', margin: '20% 0'}}>Кошик пустий</h2>}
      {isFormOpen && <OrderModal isModalOpen={isFormOpen} setIsModalOpen={setIsFormOpen} partsList={partsFromStorage}/>}
    </div>
  );
};

export default CartMain;