import React from 'react';
import './ShrotFilter.scss';

const ShrotFilter = () => {
  return (
    <div className='parts_main_filters_list_container_PC'>
      <h2>Фільтри</h2>
      <ul>
        <li>
          <p>Оберіть марку авто</p>
          <select>
            <option>Оберіть марку авто</option>
          </select>
        </li>
        <li>
          <p>Оберіть модель</p>
          <select>
            <option>Оберіть модель</option>
          </select>
        </li>
        <li>
          <p>Оберіть категорію</p>
          <select>
            <option>Оберіть категорію</option>
          </select>
        </li>
        <li>
          <p>Оберіть підкатегорію</p>
          <select>
            <option>Оберіть підкатегорію</option>
          </select>
        </li>
      </ul>
      <button>Знайти запчастини</button>    
    </div>
  );
};

export default ShrotFilter;