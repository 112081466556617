import { Link } from 'react-router-dom';
import './AdminNavBar.scss';

const AdminNavBar = () => {
  return (
    <div className="admin_navbar_container">
      <nav>
        <ul>
          <li>Магазин</li>
          <li>
            <Link to="/admin/avtoshrot" className="link">
              Автошрот
            </Link>
          </li>
          <li>
            <Link to="/admin/cars-from-usa" className="link">
              Авто із США
            </Link>
          </li>
          <li>
            <Link to="/admin/orders/consultation" className="link">
              Замовлення
            </Link>
          </li>
          <li>Статистика</li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminNavBar;
