import React from 'react';
import './PartFromShrotAnalogsCard.scss';

const PartFromShrotAnalogsCard = ({part, handleAnalogsPartsSelect, selectedAnalogsIds}) => {
  return (
      <tr className={selectedAnalogsIds.includes(part.id) ? 'selected_part_card_from_shrot_container_PC' :'part_card_from_shrot_container_PC'}onClick={() =>handleAnalogsPartsSelect(part.id)}>
        <td className='part_card_from_shrot_img_container_PC'>
          <img src={part.partImg} alt='part_img'></img>
        </td>
        <td>{part.partName}</td>
        <td>{part.manufacturer}</td>
        <td>{part.partCode}</td>
        <td>{part.sellingPrice}$</td>
      </tr>
  );
};

export default PartFromShrotAnalogsCard;