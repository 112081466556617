import React from 'react';
import './OrdersNavBar.scss';
import { Link } from 'react-router-dom';


const OrdersNavBar = () => {
  return (
    <div className='admin-orders-navbar-container'>
      <nav className='admin-orders-navbar'>
        <ul className='admin-orders-navbar-list'>
          <li>
            <Link to='/admin/orders/consultation' className='link'>Консультації</Link>
          </li>
          <li>
            <Link to='/admin/orders/parts' className='link'>Запчастини</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default OrdersNavBar;