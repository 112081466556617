import React, { useEffect, useState } from 'react';
import './PartsMain.scss';
import filterIcon from '../../assets/filterIcon.png';
import PartFromShrotCard from '../../components/PartFromShrotCard/PartFromShrotCard';
import PartFromShrotCardPC from '../../components/PartFromShrotCardPC/PartFromShrotCardPC';
import ShrotFilter from '../../components/ShrotFilter/ShrotFilter';
import Consultation from '../../components/Consultation/Consultation';
import LinearIndeterminate from '../../components/LoaderLeaner/LoaderLeaner';

const PartsMain = () => {

  const [partsList, setPartsList] = useState([]);
  const [isPartsFinded, setIsPartsFinded] = useState(true);

  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
      fetch(`https://dev.goliusauto.com${url.pathname}`)
      .then(res => {
        if(res.status === 200){
          return res.json();
        }
        else if(res.status === 204){
          setIsPartsFinded(false);
          return [];
        }
      })
      .then(data => setPartsList(data))
    
  }, []);

  return (
    <div className='parts_main'>
      <div className='filters_container'>
        <img src={filterIcon} alt='filter_icon' className='filter_icon'></img>
        <p>Фільтри</p>
      </div>
      <div>
        <ShrotFilter/>
        <Consultation/>
      </div>
      <div className='parts_list_container'>
        <div className='parts_list'>
          {partsList.map((part) => {
            return(
              <PartFromShrotCard
                part={part}
                key={part.id}
              />
            )
          })}
        </div>
        {partsList.length<1 && isPartsFinded && <div className='leaner_loader'>
          <LinearIndeterminate/>
        </div>}
        {!isPartsFinded && <h2 className='no_parts_text'>Запчастин не знайдено</h2>}
      </div>
      <div className='parts_list_container_PC'>
        <div className='parts_list_PC'>
          <table className='parts_list_table_PC'>
            <tr>
              <th></th>
              <th>Назва деталі</th>
              <th>Виробник</th>
              <th>Номер деталі</th>
              <th>Стан</th>
              <th>Наявність</th>
              <th>Ціна</th>
              <th></th>
            </tr>
            {partsList.map((part) => {
              return(
                  <PartFromShrotCardPC
                    part={part}
                    key={part.id}
                  />
              )
            })}
          </table>
        </div>
        {partsList.length<1 && isPartsFinded && <div className='leaner_loader'>
          <LinearIndeterminate/>
        </div>}
        {!isPartsFinded && <h2 className='no_parts_text'>Запчастин не знайдено</h2>}
      </div>
    </div>
  );
};

export default PartsMain;