import React from 'react';
import AdminNavBar from '../../components/AdminNavBar/AdminNavBar';
import AdminAvtoshrotMain from '../../views/AdminAvtoshrotMain/AdminAvtoshrotMain';

const AdminAvtoshrotPage = () => {
  return (
    <div>
      <AdminNavBar />
      <AdminAvtoshrotMain />
    </div>
  );
};

export default AdminAvtoshrotPage;
