import React, { Suspense } from 'react';
import Loader from './components/Loader/Loader';
import { Route, Routes } from 'react-router-dom';
import AdminCarsFromUsaPage from './pages/AdminCarsFromUsaPage/AdminCarsFromUsaPage';
import CarFromUsaPage from './pages/CarFromUsaPage/CarFromUsaPage';
import AvtoShrotPage from './pages/AvtoShrotPage/AvtoShrotPage';
import AdminAvtoshrotPage from './pages/AdminAvtoshrotPage/AdminAvtoshrotPage';
import PartsPage from './pages/PartsPage/PartsPage';
import ShrotPart from './views/ShrotPart/ShrotPart';
import CartPage from './pages/CartPage/CartPage';
import AdminOrders from './pages/AdminOrders/AdminOrders';
const Layout = React.lazy(() => import('./layout/Layout/Layout'));
// import Layout from './layout/Layout/Layout'
const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'));
// import HomePage from './pages/HomePage/HomePage'
const ShopPage = React.lazy(() => import('./pages/ShopPage/ShopPage'));
// import ShopPage from './pages/ShopPage/ShopPage'
const CarsFromUsaPage = React.lazy(() => import('./pages/CarsFromUsaPage/CarsFromUsaPage'));
const AdminLoginPage = React.lazy(() => import('./pages/AdminLoginPage/AdminLoginPage'));

function App() {
  return (
    <Layout>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/shop" Component={ShopPage} />
          <Route path="/cars-from-usa" Component={CarsFromUsaPage} />
          <Route path="/cars-from-usa/:id" Component={CarFromUsaPage} />
          <Route path="/avtoshrot" Component={AvtoShrotPage} />
          <Route path="/admin" Component={AdminLoginPage} />
          <Route path="/admin/cars-from-usa" Component={AdminCarsFromUsaPage} />
          <Route path="/admin/avtoshrot" Component={AdminAvtoshrotPage} />
          <Route path='/avtoshrot/parts/:brandId/:modelId' Component={PartsPage} />
          <Route path='/avtoshrot/parts/:brandId/:modelId/:categoryId' Component={PartsPage} />
          <Route path='/avtoshrot/parts/:brandId/:modelId/:categoryId/:subCategoryId' Component={PartsPage} />
          <Route path='/avtoshrot/search/:searchCode' Component={PartsPage} />
          <Route path='/avtoshrot/parts/:partId' Component={ShrotPart}/>
          <Route path='/avtoshrot/cart' Component={CartPage}/>
          <Route path='/admin/orders' Component={AdminOrders}/>
          <Route path='/admin/orders/:orderType' Component={AdminOrders}/>
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
