import React from 'react';
import OrdersNavBar from '../../components/OrdersNavBar/OrdersNavBar';
import OrdersMain from '../../views/OrdersMain/OrdersMain';
import AdminNavBar from '../../components/AdminNavBar/AdminNavBar';

const AdminOrders = () => {
  return (
    <div>
      <AdminNavBar/>
      <div style={{display: 'flex'}}>
        <OrdersNavBar />
        <OrdersMain />
      </div>
    </div>
  );
};

export default AdminOrders;