import React from 'react';
import PartsHero from '../../views/PartsHero/PartsHero';
import PartsMain from '../../views/PartsMain/PartsMain';

const PartsPage = () => {
  return (
    <div>
      <PartsHero/>
      <PartsMain/>
    </div>
  );
};

export default PartsPage;