import './Cart.scss';
import CartIcon from '../../assets/CartImg.png';
import { useEffect, useState } from 'react';

const Cart = () => {

  const [cartPartsCount, setCartPartsCount] = useState(0);
  const [cartParts, setCartParts] = useState(JSON.parse(localStorage.getItem('partsInCart')));

  useEffect(() => {
    if(cartParts){
      setCartPartsCount(cartParts.length);
    }
    else{
      setCartPartsCount(0);
    }
  }, [cartParts])

  return (
    <div className='cart_container'>
      <img src={CartIcon} alt='cart_icon'/>
      <p className='cart_parts_count'>{cartPartsCount}</p>
    </div>
  );
};

export default Cart;