import { useEffect, useState } from 'react';
import AdminNavBar from '../../components/AdminNavBar/AdminNavBar';
import './AdminCarsFromUsaPage.scss';
import AddCarModal from '../../components/AddCarModal/AddCarModal';

const AdminCarsFromUsaPage = () => {
  const [carsInStock, setCarsInStock] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetch('https://dev.goliusauto.com/cars-from-usa')
      .then(res => res.json())
      .then(data => setCarsInStock(data));
  }, []);

  const handleCarDelete = async carId => {
    try {
      const response = await fetch(
        `https://dev.goliusauto.com/admin/cars-from-usa/${carId}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        },
      );
      if (response.ok) {
        alert('Авто видалене');
      } else {
        alert('Авто не видалене');
      }
    } catch (error) {
      console.error('Помилка при видаленні авто:', error);
    }
  };

  return (
    <div>
      <AdminNavBar />
      <div className="admin_cars_from_usa_main">
        <div className="add_car_button_container">
          <button
            className="admin_add_car_button"
            onClick={() => setIsModalOpen(true)}
          >
            Додати нове авто
          </button>
          {isModalOpen && (
            <AddCarModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>
        <div className="admin_cars_container">
          {carsInStock.map(car => {
            return (
              <div key={car.id} className="admin_car_container">
                <div>
                  <img src={car.picture} alt="car_img" />
                </div>
                <div className="admin_car_details_container">
                  <p>{car.title}</p>
                  <p>Ціна {car.price}$</p>
                  <div className="admin_car_change_buttons">
                    <button>Редагувати</button>
                    <button onClick={() => handleCarDelete(car.id)}>
                      Видалити
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminCarsFromUsaPage;
