import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './ChooseCarModelModal.scss';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: '#131414',
  border: '2px solid #000',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 24,
  p: 2,
  color: 'white',
  '@media (min-width: 1024px)': {
    width: '40vw',
  },
};

const ChooseCarModelModal = ({isModalOpen, carIcon, setIsModalOpen, carBrand, carBrandId}) => {

  const [carModelsList, setCarModelsList] = useState([]);
  const [carCategoriesList, setCarCategoriesList] = useState([]);
  const [carSubCategoriesList, setCarSubCategoriesList] = useState([]);
  const [carModel, setCarModel] = useState(undefined);
  const [carCategory, setCarCategory] = useState(undefined);
  const [carSubCategory, setCarSubCategory] = useState(undefined);
  const [linkToParts, setLinkToParts] = useState('');

  useEffect(() => {
    fetch(`https://dev.goliusauto.com/avtoshrot/${carBrandId}/carModels`)
      .then(res => res.json())
      .then(data => setCarModelsList(sortByModelName(data)));
  }, [carBrandId]);

  useEffect(() => {
    if(carModel){
      fetch('https://dev.goliusauto.com/avtoshrot/categories')
      .then(res => res.json())
      .then(data => setCarCategoriesList(data))
    }
  }, [carModel, carBrand])

  useEffect(() => {
    if(carCategory){
      fetch(`https://dev.goliusauto.com/avtoshrot/${carCategory}/subCategories`)
      .then(res => res.json())
      .then(data => setCarSubCategoriesList(data));
    }
  }, [carCategory])

  useEffect(() => {
    if(carBrandId && carModel && carCategory && carSubCategory){
      setLinkToParts(`/avtoshrot/parts/${carBrandId}/${carModel}/${carCategory}/${carSubCategory}`)
    }
    else if(carBrandId && carModel && carCategory && !carSubCategory){
      setLinkToParts(`/avtoshrot/parts/${carBrandId}/${carModel}/${carCategory}`)
    }
    else if(carBrandId && carModel && !carCategory && !carSubCategory){
      setLinkToParts(`/avtoshrot/parts/${carBrandId}/${carModel}`)
    }
  }, [carBrandId, carModel, carCategory, carSubCategory])

  const handleClose = () => setIsModalOpen(false);

  const handleCarModelChange = event => setCarModel(event.target.value);
  const handleCarCategoryChange = event => setCarCategory(event.target.value);
  const handleSubCategoryChange = event => setCarSubCategory(event.target.value);

  const handleCarModelSubmit = async event => {
    event.preventDefault();
    
  };

  const sortByModelName = (data) => {
    const sortedArray = data.slice().sort((a, b) => {
      const modelNameA = a.modelName.toUpperCase();
      const modelNameB = b.modelName.toUpperCase();

      if (modelNameA < modelNameB) {
        return -1;
      } else if (modelNameA > modelNameB) {
        return 1;
      } else {
        return 0;
      }
    })
    return sortedArray;
  }

  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Оберіть необхідну запчастину
          </Typography>
          <form
            className="choose_car_model_form"
            onSubmit={handleCarModelSubmit}
          >
            <div className='choose_car_modal_model_carBrand'>
              <p>Обрана марка авто: {carBrand}</p>
              <img src={carIcon} alt='car_icon'></img>
            </div>
            <div>
              <p>Оберіть модель авто</p>
              <select value={carModel} onChange={handleCarModelChange}>
                {!carModel && <option value={undefined}>Оберіть модель</option>}
                  {carModelsList.map(model => {
                    return <option key={model.id} value={model.id}>{model.modelName}</option>;
                  })}
              </select>
            </div>
            {carModel && <div>
              <p>Оберіть категорію</p>
              <select value={carCategory} onChange={handleCarCategoryChange}>
                {!carCategory && <option value={undefined}>Оберіть категорію</option>}
                {carCategoriesList.map(category => {
                  return <option key={category.id} value={category.id}>{category.categoryName}</option>
                })}
              </select>
            </div>}
            {carCategory && <div>
              <p>Оберіть підкатегорію</p>
              <select value={carSubCategory} onChange={handleSubCategoryChange}>
                {!carSubCategory && <option value={undefined}>Оберіть підкатегорію</option>}
                {carSubCategoriesList.map(subCategory => {
                  return <option key={subCategory.id} value={subCategory.id}>{subCategory.subCategoryName}</option>
                })}
              </select>
            </div>}
            {carModel && 
              <Link to={linkToParts} className='link'>
                <button type='submit' className='choose_car_model_submit_btn'>Продовжити</button>
              </Link>
            }
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#choose_car_model_modal'),
  );
};

export default ChooseCarModelModal;
