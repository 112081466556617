import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './EditPartModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '95vh',
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const EditPartModal = ({ isModalOpen, setIsModalOpen }) => {
  const [carBrandId, setCarBrandId] = useState('');
  const [carModelId, setCarModelId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [partId, setPartId] = useState('');
  const [carBrandsList, setCarBrandsList] = useState([]);
  const [carModelsList, setCarModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [partsList, setPartsList] = useState([]);
  
  const [partName, setPartName] = useState('');
  const [partImg, setPartImg] = useState('');
  const [partCode, setPartCode] = useState('');
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [state, setState] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [manufacturer, setManufacturer] = useState('');
  const [description, setDescription] = useState('');
  const [additionalPhotos, setAdditionalPhotos] = useState([]);

  useEffect(() => {
    fetch('https://dev.goliusauto.com/admin/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setCarBrandsList(data))
  }, [])

  useEffect(() => {
    fetch('https://dev.goliusauto.com/avtoshrot/categories')
    .then(res => res.json())
    .then(data => setCategoriesList(data))
  }, [])

  useEffect(() => {
    if(categoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/${categoryId}/subCategories`)
      .then(res => res.json())
      .then(data => setSubCategoriesList(data))
    }
  }, [categoryId])

  useEffect(() => {
    if(carBrandId)
    fetch(`https://dev.goliusauto.com/admin/avtoshrot/carModels/${carBrandId}`)
    .then(res => res.json())
    .then(data => setCarModelsList(data.slice().sort((a, b) => {
      const modelNameA = a.modelName.toUpperCase();
      const modelNameB = b.modelName.toUpperCase();

      if (modelNameA < modelNameB) {
        return -1;
      } else if (modelNameA > modelNameB) {
        return 1;
      } else {
        return 0;
      }
    })))
  }, [carBrandId])

  useEffect(() => {
    if(carBrandId && carModelId && categoryId && subCategoryId){
      fetch(`https://dev.goliusauto.com/avtoshrot/parts/${carBrandId}/${carModelId}/${categoryId}/${subCategoryId}`)
      .then(res => res.json())
      .then(data => setPartsList(data))
    }
  }, [carBrandId, carModelId, categoryId, subCategoryId])

  useEffect(() => {
    if(partId){
      fetch(`https://dev.goliusauto.com/avtoshrot/parts/${partId}`)
      .then(res => res.json())
      .then(data => {
        setPartName(data[0].partName);
        setPartImg(data[0].partImg);
        setPartCode(data[0].partCode);
        setPurchasePrice(data[0].purchasePrice);
        setSellingPrice(data[0].sellingPrice);
        setState(data[0].state);
        setQuantity(data[0].quantity);
        setManufacturer(data[0].manufacturer);
        setDescription(data[0].description);
        setAdditionalPhotos(data[0].additionalPhotos.join(' '))
      })
    }
  }, [partId])

  const handleCarBrandChange = event => setCarBrandId(event.target.value);
  const handleCarModelChange = event => setCarModelId(event.target.value);
  const handleCategoryChange = event => setCategoryId(event.target.value);
  const handleSubCategoryChange = event => setSubCategoryId(event.target.value);
  const handlePartChange = event => setPartId(event.target.value);
  const handlePartNameChange = event => setPartName(event.target.value);
  const handlePartImgChange = event => setPartImg(event.target.value);
  const handlePartCodeChange = event => setPartCode(event.target.value);
  const handlePurchasePriceChange = event => setPurchasePrice(event.target.value);
  const handleSellingPriceChange = event => setSellingPrice(event.target.value);
  const handleStateChange = event => setState(event.target.value);
  const handleQuantityChange = event => setQuantity(event.target.value);
  const handleManufacturerChange = event => setManufacturer(event.target.value);
  const handleDescriptionChange = event => setDescription(event.target.value);
  const handleAdditionalPhotosChange = event => setAdditionalPhotos(event.target.value);

  const handleClose = () => setIsModalOpen(false);

  const handleCarPartSubmit = async event => {
    event.preventDefault();
    if(partId){
      const editedObj = {
        partId: partId,
        partName: partName,
        partImg: partImg,
        partCode: partCode,
        purchasePrice: purchasePrice,
        sellingPrice: sellingPrice,
        state: state,
        quantity: quantity,
        manufacturer: manufacturer,
        description: description,
        additionalPhotos: additionalPhotos.split(' ').map(link => link.trim())
      }
  
     const result = await fetch(`https://dev.goliusauto.com/admin/avtoshrot/parts/editPart`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedObj),
      })
      if(result){
        alert('Запчастину відредаговано')
      }
      else{
        alert('Запчастину не відредаговано')
      }
    }
    else{
      alert('Виберіть запчастину яку треба відредагувати');
    }
  };

  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Редагувати Автозапчастину
          </Typography>
          <form
            className="edit_car_part_form"
            onSubmit={handleCarPartSubmit}
          >
            <div className='admin_parts_edit_container'>
              <div className='admin_parts_edit_subcontainer_1'>
                <div className="admin_parts_choose_brand_container">
                  <p>Оберіть марку авто</p>
                  <select value={carBrandId} onChange={handleCarBrandChange}>
                    <option value={undefined}>Оберіть марку</option>
                    {carBrandsList.map(carBrand => {
                      return (
                        <option key={carBrand.id} value={carBrand.id}>
                          {carBrand.carBrandName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="admin_parts_choose_model_container">
                  <p>Оберіть модель авто</p>
                  <select value={carModelId} onChange={handleCarModelChange}>
                    <option value={null}>Оберіть модель</option>
                    {carModelsList.map(model => {
                      return <option key={model.id} value={model.id}>{model.modelName}</option>;
                    })}
                  </select>
                </div>
                <div className="admin_parts_choose_category_container">
                  <p>Оберіть категорію</p>
                  <select value={categoryId} onChange={handleCategoryChange}>
                    <option value={null}>Оберіть категорію</option>
                    {categoriesList.map(category => {
                      return <option key={category.id} value={category.id}>{category.categoryName}</option>;
                    })}
                  </select>
                </div>
                <div className="admin_parts_choose_subCategory_container">
                  <p>Оберіть підкатегорію</p>
                  <select value={subCategoryId} onChange={handleSubCategoryChange}>
                    <option value={null}>Оберіть підкатегорію</option>
                    {subCategoriesList.map(subCategory => {
                      return <option key={subCategory.id} value={subCategory.id}>{subCategory.subCategoryName}</option>;
                    })}
                  </select>
                </div>
                <div className="admin_parts_choose_subCategory_container">
                  <p>Оберіть запчастину</p>
                  <select value={partId} onChange={handlePartChange}>
                    <option value={null}>Оберіть запчастину</option>
                    {partsList.map(part => {
                      return <option key={part.id} value={part.id}>{part.partName}    Код: {part.partCode}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className='admin_parts_edit_subcontainer_2'>
                <h2>Редагування</h2>
                <div>
                  <p>Назва</p>
                  <input
                    type='text'
                    value={partName}
                    onChange={handlePartNameChange}
                    required
                  />
                </div>
                <div>
                  <p>Зображення</p>
                  <input
                    type='text'
                    value={partImg}
                    onChange={handlePartImgChange}
                    required
                  />
                </div>
                <div>
                  <p>Код</p>
                  <input
                    type='text'
                    value={partCode}
                    onChange={handlePartCodeChange}
                    required
                  />
                </div>
                <div>
                  <p>Закупочна ціна</p>
                  <input
                    type='number'
                    value={purchasePrice}
                    onChange={handlePurchasePriceChange}
                    required
                  />
                </div>
                <div>
                  <p>Ціна продажу</p>
                  <input
                    type='number'
                    value={sellingPrice}
                    onChange={handleSellingPriceChange}
                    required
                  />
                </div>
                <div>
                  <p>Стан</p>
                  <select value={state} onChange={handleStateChange}>
                    <option value={'Новий'}>Новий</option>
                    <option value={'Б/У'}>Б/У</option>
                  </select>
                </div>
                <div>
                  <p>Кількість</p>
                  <input
                    type='number'
                    value={quantity}
                    onChange={handleQuantityChange}
                    required
                  />
                </div>
                <div>
                  <p>Виробник</p>
                  <input
                    type='text'
                    value={manufacturer}
                    onChange={handleManufacturerChange}
                    required
                  />
                </div>
                <div>
                  <p>Опис</p>
                  <input
                    type='text'
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                  />
                </div>
                <div>
                  <p>Додаткові фото</p>
                  <textarea
                    style={{resize: 'none', width: '90%'}} 
                    rows="7" 
                    cols="50"
                    name='additionalPhotos'
                    value={additionalPhotos}
                    onChange={handleAdditionalPhotosChange}
                  ></textarea>
                </div>
              </div>       
            </div>
            <button type="submit">Редагувати запчастину</button>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#edit_part_modal'),
  );
};

export default EditPartModal;
