import { createPortal } from 'react-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './DeleteCarModelModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#131414',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  color: 'white',
};

const DeleteCarModelModal = ({ isModalOpen, setIsModalOpen }) => {
  const [carBrandId, setCarBrandId] = useState('');
  const [carModelId, setCarModelId] = useState('');
  const [carBrandsList, setCarBrandsList] = useState([]);
  const [carModelsList, setCarModelsList] = useState([]);
  

  useEffect(() => {
    fetch('https://dev.goliusauto.com/admin/avtoshrot/carBrands')
    .then(res => res.json())
    .then(data => setCarBrandsList(data))
  })

  useEffect(() => {
    if(carBrandId)
    fetch(`https://dev.goliusauto.com/admin/avtoshrot/carModels/${carBrandId}`)
    .then(res => res.json())
    .then(data => setCarModelsList(data.slice().sort((a, b) => {
      const modelNameA = a.modelName.toUpperCase();
      const modelNameB = b.modelName.toUpperCase();

      if (modelNameA < modelNameB) {
        return -1;
      } else if (modelNameA > modelNameB) {
        return 1;
      } else {
        return 0;
      }
    })))
  }, [carBrandId])

  const handleCarBrandChange = event => setCarBrandId(event.target.value);
  const handleCarModelChange = event => setCarModelId(event.target.value);

  const handleClose = () => setIsModalOpen(false);

  const handleCarModelSubmit = async event => {
    event.preventDefault();

    if(carModelId){
      const response = await fetch(`https://dev.goliusauto.com/admin/avtoshrot/deleteModel/${carModelId}`, {
        method: 'DELETE'
      })
      if(response.ok){
        alert('Модель авто видалено');
        setIsModalOpen(false);
      }
      else{
        alert('Модель авто не видалено');
      }
    }
    else{
      alert('Оберіть марку та модель авто');
    }
  };

  return createPortal(
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Видалити модель авто
          </Typography>
          <form
            className="delete_car_model_form"
            onSubmit={handleCarModelSubmit}
          >
            <div>
              <div className="admin_choose_brand_container">
                <p>Оберіть марку авто</p>
                <select value={carBrandId} onChange={handleCarBrandChange}>
                  <option value={undefined}>Оберіть марку</option>
                  {carBrandsList.map(carBrand => {
                    return (
                      <option key={carBrand.id} value={carBrand.id}>
                        {carBrand.carBrandName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="admin_choose_model_container">
                <p>Оберіть модель авто</p>
                <select value={carModelId} onChange={handleCarModelChange}>
                  <option value={null}>Оберіть модель</option>
                  {carModelsList.map(model => {
                    return <option key={model.id} value={model.id}>{model.modelName}</option>;
                  })}
                </select>
              </div>
            </div>
            <button type="submit">Видалити модель авто</button>
          </form>
        </Box>
      </Modal>
    </div>,
    document.querySelector('#delete_car_model_modal'),
  );
};

export default DeleteCarModelModal;
